<template>
  <validation-provider
    #default="{ errors,touched }"
    :sync-value="value"
    :vid="name"
    :name="$t(formatName(name))"
    :rules="rules.replace('numeric','')"
    :immediate="true"
  >
    <b-form-group>
      <label
        v-if="showLabel"
        :for="$t(label)"
      ><span v-html="$t(label)" /> <b
        v-if="isRequired"
        class="text-danger"
        style="font-size: 15px"
      >*</b></label>
      <b-input-group class="input-group-merge ">
        <b-input-group-prepend
          v-if="prefixIcon"
          is-text
        >
          <feather-icon
            class="cursor-pointer"
            :icon="prefixIcon"
            @click="prefixIconClicked"
          />
        </b-input-group-prepend>
        <b-form-input
          :id="name"
          :value="value"
          :state="errors.length > 0 && touched? false:null"
          :name="name"
          :placeholder="$t(placeholder)||$t(label)"
          :type="type"
          :readonly="readonly"
          @input="inputChanged"
          @keypress.enter="enterPressed"
        />
        <b-input-group-append
          v-if="suffixIcon"
          is-text
        >
          <feather-icon
            v-b-tooltip.hover="$t(tooltip)"
            class="cursor-pointer"
            :icon="suffixIcon"
            @click="suffixIconClicked"
          />
        </b-input-group-append>

      </b-input-group>
      <small
        v-if="showErrors && touched"
        class="text-danger text-capitalize"
      >{{ errors[0] }}</small>
    </b-form-group>
  </validation-provider>
</template>

<script>
import {
  BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BInputGroupAppend, VBTooltip,
} from 'bootstrap-vue'
/* eslint-disable global-require */
import { ValidationProvider } from 'vee-validate'
import {
  required, email, maxValue, minValue, maxLength, minLength, numeric, phone,validAcademicYear,validAnswer
} from '@core/utils/validations/validations'

export default {
  name: 'LenonInput',
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormGroup,
    ValidationProvider,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    name: {
      type: String,
      default: 'input',
    },
    label: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    showErrors: {
      type: Boolean,
      default: true,
    },
    suffixIcon: {
      type: String,
      default: 'Edit2Icon',
    },
    prefixIcon: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number],
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    tooltip: {
      type: String,
      default: '',
    },
    rules: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      email,
      maxValue,
      minValue,
      maxLength,
      minLength,
      numeric,
      phone,
      validAcademicYear,
      validAnswer,
    }
  },
  computed: {
    isRequired() {
      return this.rules.includes('required')
    },
  },
  methods: {
    formatName(name) {
      return name.replaceAll('_', ' ')
    },
    suffixIconClicked(e) {
      this.$emit('onSuffixIconClick', e)
    },
    prefixIconClicked(e) {
      this.$emit('onPrefixIconClick', e)
    },
    enterPressed(e) {
      this.$emit('onEnterPressed', e.target.value)
    },
    inputChanged(e) {
      this.$emit('input', e.trim())
    },
  },
}
</script>

<style scoped>

</style>
